import React from 'react';
import * as styles from './ProductCollectionGrid.module.css';

import ProductCollection from '../ProductCollection';

/**
 *
 * @param {{
 * collections: {
 *  nodes: import('../../types/global/').ProductCategoryType[]
 * }
 * }} list of new collections(categories)
 */
const ProductCollectionGrid = ({ collections }) => {
  return (
    <div className={styles.root}>
      {collections?.nodes?.map((collection, index) => (
        <ProductCollection
          key={`new-collection-${collection.slug}`}
          // todo: add collection(category) image
          image={``}
          title={collection.name}
          text={'Acheter maintenant'}
          link={`/category/${collection.slug}/`}
        />
      ))}
    </div>
  );
};

export default ProductCollectionGrid;
