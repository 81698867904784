import * as React from 'react';
import { graphql, navigate } from 'gatsby';

import Container from '../components/Container';
import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import ProductCollectionGrid from '../components/ProductCollectionGrid';
import ProductCardGrid from '../components/ProductCardGrid';
import Quote from '../components/Quote';
import Title from '../components/Title';
import Seo from '../components/seo';

import * as styles from './index.module.css';

const IndexPage = ({ data: { pageData, collections, newArrivals } }) => {

  const goToShop = () => {
    navigate('/shop/');
  };
  const dates = {
    first_publication_date: pageData?.date || new Date().toISOString(),
    last_publication_date: pageData?.modified || new Date().toISOString(),
  };

  return (
    <Layout disablePaddingBottom>
      <Seo
        title={pageData?.title || ''}
        banner={pageData?.featured_media || ''}
        node={dates}
        pathname={'/'}
        description={pageData?.content || ''}
      />
      {/* Hero Container */}
      <Hero
        maxWidth={'500px'}
        image={pageData?.featured_media}
        title={''}
        subtitle={''}
        ctaText={'acheter maintenant'}
        ctaAction={goToShop}
      />

      {/* Collection Container */}
      <div className={styles.collectionContainer}>
        <Container size={'large'}>
          <Title name={'Nouvelle collection'} />
          <ProductCollectionGrid collections={collections} />
        </Container>
      </div>

      {/* New Arrivals */}
      <div className={styles.newArrivalsContainer}>
        <Container>
          <Title
            name={'Nouveaux arrivants'}
            link={'/shop/'}
            textLink={'voir tout'}
          />
          <ProductCardGrid
            spacing={true}
            showSlider={newArrivals.nodes?.length > 1}
            height={480}
            columns={3}
            data={newArrivals.nodes}
          />
        </Container>
      </div>

      {/* Quote */}
      <Quote
        bgColor={'var(--standard-light-grey)'}
        title={'à propos'}
        quote={pageData?.content || ''}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ProductListings {
    pageData: zzPage(page_type: { eq: "home" }, status: { eq: "publish" }) {
      id
      title
      type
      date
      content
      status
      page_type
      zzenz_id
      featured_media
    }
    collections: allWcProductsCategories(
      filter: { count: { gt: 0 } }
      sort: { fields: term_id, order: DESC }
      limit: 4
    ) {
      nodes {
        name
        slug
        count
      }
    }
    newArrivals: allWcProducts(
      sort: { fields: [wordpress_id], order: DESC }
      filter: {
        wordpress_id: {
          nin: [0, -1]
        }
      },
      limit: 3
    ) {
      nodes {
        id
        wordpress_id
        slug
        name
        price
        short_description
        regular_price
        status
        attributes {
          id
          name
          options
          visible
        }
        images {
          id
          src
          height
          width
        }
      }
    }
  }
`;

export default IndexPage;
