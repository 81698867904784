import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { getImageLink } from '../../helpers/images';
import * as styles from './ProductCollection.module.css';

const ProductCollection = (props) => {
  const { image, title, text, link } = props;
  const heroImage = {
    backgroundColor: '#B59F66aa',
    layout: 'constrained',
    images: {
      fallback: {
        src: image || '/banner1.png',
      },
      sources: [
        {
          media: '',
          type: 'image/webp',
          srcSet: image ? getImageLink(image) : '/banner1.png',
        },
      ],
    },
  };

  return (
    <Link role={'presentation'} to={link} className={styles.root}>
      {
        !!image && (
          <GatsbyImage image={heroImage} alt={title} className={styles.image_bg} />
        )
      }
      <div className={styles.content}>
        <span className={styles.title}>{title}</span>
        <span className={styles.text}>{text}</span>
      </div>
      <div className={styles.overlay}></div>
    </Link>
  );
};

export default ProductCollection;
