import React from 'react';
import * as styles from './Quote.module.css';

const Quote = (props) => {
  const { bgColor, title, quote } = props;
  const quoteContent = {
    __html: quote,
  };

  if (!!quote === false) return null;
  return (
    <div className={styles.root} style={{ backgroundColor: bgColor }}>
      <span>{title}</span>
      <div dangerouslySetInnerHTML={quoteContent} />
    </div>
  );
};

export default Quote;
